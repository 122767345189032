import {
  Box,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { flexCentered, shadedWhite } from '@styles/shared/SCss';
import { Svgs } from '@utils/svgs';
import useUtils from '@hooks/useUtils';
import { ChainId } from '@constants/CChains';
import { ServerNetwork, toRem } from '@utils/functions';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useNotification from '@hooks/useNotification';

const ChainSelect = styled(Select)(() => ({
  backgroundColor: 'transparent',
  '& .MuiSelect-select': {
    padding: '5px !important',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    ...shadedWhite,
  },
}));

interface Props {
  switchNetwork: (providedChainId: string) => Promise<void>;
  chainId: string | null;
}

function NetworkSelectComp({ switchNetwork, chainId }: Props) {
  const { notifyInfo } = useNotification();

  const { serverNetwork } = useUtils();

  const [currentChain, setCurrentChain] = useState<string>(ChainId.BSC);

  const theme = useTheme();

  const SelectMenuProps = {
    sx: {
      padding: '10px',
      top: '16px',
      left: '-2px',
      '.MuiPaper-root': {
        minWidth: '170px !important',
        backdropFilter: 'blur(20px)',
        border: `1px solid ${theme.palette.secondary.light} !important`,
        borderRadius: toRem(8),
      },
      '.MuiList-root': {
        padding: '5px',
        '& .MuiButtonBase-root + .MuiButtonBase-root': {
          marginTop: '5px',
        },
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        color: `${theme.palette.text.primary} !important`,
      },
      '.MuiButtonBase-root': {
        padding: '5px',
        color: theme.palette.text.secondary,
        borderRadius: toRem(4),
        fontSize: 13,
        ':hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },

        '& .MuiTypography-root': {
          color: 'inherit',
        },
      },
    },
  };

  const handleSelectChainChange = async (chainID: string) => {
    try {
      await switchNetwork(chainID);
    } catch (error) {
      notifyInfo('Request rejected!');
      throw error;
    }
  };

  const isChainValid = () => {
    let chains: string[] = [];
    // ? remove bsc testnet as valid chain.
    if (serverNetwork === 'bsc') {
      chains = Object.values(ChainId).filter((chain) => chain !== '0x61');
    } else {
      chains = Object.values(ChainId);
    }
    return Boolean(chains.find((chain) => chain === chainId));
  };

  useEffect(() => {
    const validChain = isChainValid();
    if (chainId && validChain) {
      setCurrentChain(chainId);
    } else {
      setCurrentChain('Unsupported');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  if (!chainId) {
    return null;
  }
  return (
    <Box
      sx={{
        paddingLeft: { lg: '1rem', xs: '0' },
        ...flexCentered,
      }}
    >
      <ChainSelect
        sx={{
          minWidth: { xs: '160px', lg: '170px' },
          '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
          },
        }}
        value={currentChain}
        onChange={(event) =>
          handleSelectChainChange(event.target.value as string)
        }
        MenuProps={SelectMenuProps}
        IconComponent={ExpandMoreRoundedIcon}
      >
        <MenuItem
          value={ChainId.ETH}
          disableGutters
          sx={{ paddingLeft: '0.5rem' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.ETH width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              ETH
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value={ChainId.BSC}
          disableGutters
          sx={{ paddingLeft: '0.5rem' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.BNB width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              BNB
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value={ChainId.POLYGON}
          disableGutters
          sx={{ paddingLeft: '0.5rem' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.Polygon width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              Polygon
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value={ChainId.AVALANCHE}
          disableGutters
          sx={{ paddingLeft: '0.5rem' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.Avalanche width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              Avalanche
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value={ChainId.BSC_TEST_NET}
          disableGutters
          sx={{
            paddingLeft: '0.5rem',
            display: `${
              serverNetwork === ServerNetwork.BSC_TESTNET ? 'block' : 'none'
            }`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.BNB width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              BSC TESTNET
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value="Unsupported"
          disableGutters
          sx={{ paddingLeft: '0.5rem', display: 'none' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Svgs.BNB width={19} height={19} />
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              UNSUPPORTED
            </Typography>
          </Box>
        </MenuItem>
      </ChainSelect>
    </Box>
  );
}
export default NetworkSelectComp;
