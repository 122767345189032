/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTheme, Box, useMediaQuery } from '@mui/material';
import { Svgs } from '@utils/svgs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderGrid } from '@styles/header/SHeaderGrid';
import HeaderWalletModal from '@components/header/wallet/HeaderWalletModal';
import HeaderNavbar from '@components/header/HeaderNavbar';
import { toRem } from '@utils/functions';
import customColors from '@theme/customColors';
import useUser from '@hooks/useUser';
import HeaderNavLabel from './HeaderNavLabel';

function Header() {
  const theme = useTheme();

  const navigate = useNavigate();

  const { currentUser } = useUser();

  const colors = theme.palette;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    if (currentUser) {
      navigate('/');
    } else {
      navigate('/projects');
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: '99',
        borderBottom: `1px solid ${colors.secondary.light}`,
        padding: { xs: '21px 0', lg: 0 },
        backgroundColor: customColors.darkBlue65,
        backdropFilter: 'blur(25px)',
      }}
    >
      <HeaderGrid>
        <Box
          sx={{
            marginLeft: toRem(21),
            cursor: 'pointer',
            maxWidth: 140,
            display: { xs: 'none', lg: 'flex' },
          }}
          onClick={handleNavigate}
        >
          <Svgs.Logo />
        </Box>
        {downLg && <HeaderNavLabel />}

        <HeaderNavbar handleClick={handleClick} anchorEl={anchorEl} />
      </HeaderGrid>
      {currentUser && (
        <HeaderWalletModal anchorEl={anchorEl} handleClose={handleClose} />
      )}
    </Box>
  );
}

export default Header;
