import {
  Drawer,
  Box,
  Tooltip,
  useTheme,
  Button,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChain } from 'react-moralis';
import { Svgs } from '@utils/svgs';
import colors from '@theme/customColors';
import useOffers from '@hooks/useOffers';
import HelpCenter from '@pages/HelpCenter';
import NetworkSelectComp from '@components/header/NetworkSelectComp';
import SearchResponsiveModal from '@components/header/search/SearchResponsiveModal';
import HeaderWalletResponsiveModal from '@components/header/wallet/HeaderWalletResponsiveModal';
import CloseIconCom from '@components/shared/CloseIconCom';
import HeaderNavbarResponsiveItem from '@components/header/HeaderNavbarResponsiveItem';
import { ResponsiveMenuItems } from '@constants/CSidebar';
import { TResponsiveMenu } from '@typescript/TSidebar';
import useUser from '@hooks/useUser';
import useUtils from '@hooks/useUtils';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import useActionOrConnect from '@hooks/useActionOrConnect';

interface Props {
  openHamburger: boolean | undefined;
  setOpenHamburger: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

function HeaderNavbarResponsive({ openHamburger, setOpenHamburger }: Props) {
  const handleActionOrConnect = useActionOrConnect();
  const theme = useTheme();
  const { incompatibleChain, currentNetworkLabel } = useUtils();

  const { switchNetwork, chainId } = useChain();

  const { currentUser } = useUser();

  const { getMaxOfferedPrice } = useOffers();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [openSearchModal, setSearchOpenModal] = useState<boolean>(false);

  const [openWalletModal, setOpenWalletModal] = useState<boolean>(false);

  const [openHelpCenter, setOpenHelpCenter] = useState<boolean>(false);

  const toggleSearchModal = (state: boolean) => {
    setOpenHamburger(false);
    setSearchOpenModal(state);
  };

  const toggleWalletModal = (state: boolean) => {
    setOpenHamburger(false);
    setOpenWalletModal(state);
  };

  const isActive = (path: string) => path === pathname;

  const handleNavigate = (item: TResponsiveMenu) => {
    if (!item.disabled && item.externalLink) {
      window.open(item.href);
      setOpenHamburger(false);
    }
    if (!item.disabled && !item.externalLink) {
      navigate(item.href);
      setOpenHamburger(false);
    }
  };

  const handleOpenHelpCenter = () => {
    setOpenHelpCenter(true);
    setOpenHamburger(false);
  };

  return (
    <>
      <Drawer
        className="Responsive Navbar"
        anchor="right"
        open={openHamburger}
        onClose={() => setOpenHamburger(false)}
        PaperProps={{
          sx: {
            height: 'calc(100% - 59px)',
            background: theme.palette.background.default,
            width: '195px',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              px: '1rem',
              py: '21px',
              cursor: 'pointer',
              borderBottom: `1px solid ${theme.palette.secondary.light}`,
            }}
          >
            {incompatibleChain && (
              <Tooltip
                title={`You are not on the ${currentNetworkLabel} chain`}
              >
                <Typography
                  sx={{
                    color: 'white',
                    p: '0 0.6rem',
                    backgroundColor: 'error.main',
                    borderRadius: '6px',
                    fontWeight: '700',
                  }}
                >
                  !
                </Typography>
              </Tooltip>
            )}

            <CloseIconCom
              onClick={() => setOpenHamburger(false)}
              sx={{
                ml: 'auto',
                color: 'rgba(217, 217, 217, 0.54)',
                backgroundColor: colors.darkGray,
                padding: '0.1rem',
                borderRadius: '4px',
                ':hover': { color: theme.palette.primary.main },
              }}
            />
          </Box>

          {currentUser ? (
            <>
              <Box p={2}>
                <NetworkSelectComp
                  switchNetwork={switchNetwork}
                  chainId={chainId}
                />
              </Box>
              <Box
                className="wallet-icon-container"
                mx={2.2}
                mb={1}
                sx={{
                  backgroundColor: 'secondary.light',
                  display: 'flex',
                  justifyContent: 'center',
                  p: 0.8,
                  borderRadius: '6px',
                  ':hover': { backgroundColor: 'secondary.main' },
                  cursor: 'pointer',
                  gap: '2px',
                }}
                onClick={() => toggleWalletModal(true)}
              >
                <Svgs.Portfolio
                  fill={theme.palette.primary.main}
                  style={{ opacity: 0.5 }}
                  width={24}
                  height={24}
                />
              </Box>
            </>
          ) : (
            <Box p={2}>
              <ButtonGradientDark onClick={() => handleActionOrConnect()}>
                Login
              </ButtonGradientDark>
            </Box>
          )}

          <Box
            className="Search Icon Container "
            mx={2.2}
            mb={1}
            sx={{
              backgroundColor: 'secondary.light',
              display: 'flex',
              justifyContent: 'center',
              p: 0.8,
              borderRadius: '6px',
              ':hover': { backgroundColor: 'secondary.main' },
              cursor: 'pointer',
              gap: '2px',
            }}
            onClick={() => toggleSearchModal(true)}
          >
            <SearchIcon sx={{ opacity: 0.5 }} />
          </Box>

          <Box>
            {ResponsiveMenuItems.map((item) => {
              if (item.isProjected && !currentUser) {
                return null;
              }
              if (item.name === 'Calendar') {
                const Icon = item.icon;
                return (
                  <Tooltip
                    title="Coming soon.."
                    arrow
                    placement="top"
                    key={item.name}
                  >
                    <Box sx={{ px: '18px', mb: 1 }} key={item.name}>
                      <Box
                        sx={{
                          borderTop: `1px solid ${theme.palette.secondary.light}`,
                          padding: '12px 15px',
                          border: 'none !important',
                          borderRadius: '6px',
                          display: 'flex',
                          alignItems: 'center',
                          cursor: item.disabled ? 'unset' : 'pointer',
                          gap: '0.5rem',
                          backgroundColor: isActive(item.href)
                            ? theme.palette.secondary.light
                            : 'transparent',
                          ':last-of-type': {
                            borderBottom: `1px solid ${theme.palette.secondary.light}`,
                            ':hover': {
                              backgroundColor: item.disabled
                                ? 'unset'
                                : theme.palette.secondary.light,
                            },
                          },
                        }}
                      >
                        <Icon
                          fill={theme.palette.primary.main}
                          style={{
                            width: 18,
                            height: 18,
                            opacity: item.disabled ? 0.5 : 1,
                          }}
                        />
                        <Button
                          disabled={item.disabled}
                          sx={{
                            textDecoration: 'none',
                            padding: '0',
                            fontSize: '13px',
                            fontWeight: 'bold',
                          }}
                        >
                          {item.name}
                        </Button>
                      </Box>
                    </Box>
                  </Tooltip>
                );
              }
              return (
                <HeaderNavbarResponsiveItem
                  key={item.href}
                  item={item}
                  handleNavigate={handleNavigate}
                  isActive={isActive}
                  handleOpenHelpCenter={handleOpenHelpCenter}
                />
              );
            })}
          </Box>
        </Box>
      </Drawer>
      <SearchResponsiveModal
        open={openSearchModal}
        closeModal={() => toggleSearchModal(false)}
        getMaxOfferedPrice={getMaxOfferedPrice}
      />
      {currentUser && (
        <HeaderWalletResponsiveModal
          open={openWalletModal}
          closeModal={() => toggleWalletModal(false)}
        />
      )}
      <HelpCenter
        open={openHelpCenter}
        onClose={() => setOpenHelpCenter(false)}
      />
    </>
  );
}

export default HeaderNavbarResponsive;
